import React, { useState, useCallback } from 'react';
import Collapsible from 'react-collapsible';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useStyles, useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { VIEWER_HOOKS } from '../../../../../constants';
import { useStylesParams, useSettingsParams } from '../../../../../hooks';
import { BenefitBulletType } from '../../../../../types/common';
import { alignmentName } from '../../../../../utils/alignment';
import { iconsMap } from '../../../commons/bulletIcons';
import { ArrowDown } from './ArrowDown';
import { st, classes } from './Footer.st.css';

interface FooterProps {
  benefits: string[];
  highlighted: boolean;
  anyBenefits?: boolean;
}

export const Footer: React.FC<FooterProps> = ({ benefits, anyBenefits, highlighted }) => {
  const { isMobile } = useEnvironment();
  const stylesParams = useStylesParams();
  const styles = useStyles();
  const showPlanBenefits = styles.get(stylesParams.showBenefits);

  if (!showPlanBenefits) {
    return null;
  }
  if (isMobile) {
    return benefits.length > 0 ? <MobileBenefits benefits={benefits} highlighted={highlighted} /> : null;
  } else {
    return anyBenefits ? <Benefits benefits={benefits} mobile={false} highlighted={highlighted} /> : null;
  }
};

interface BenefitsProps {
  benefits: string[];
  highlighted: boolean;
  mobile: boolean;
}

const Benefits: React.FC<BenefitsProps> = React.memo(({ benefits, highlighted, mobile }) => {
  const stylesParams = useStylesParams();
  const styles = useStyles();
  const { isRTL } = useEnvironment();

  const benefitsAlignment = alignmentName(styles.get(stylesParams.benefitsAlignment));

  return (
    <ul
      data-hook={VIEWER_HOOKS.BENEFITS}
      className={st(classes.benefits, {
        highlighted,
        mobile,
        textAlignment: benefitsAlignment,
        isRTL,
      })}
    >
      {benefits.map((benefit, i) => (
        <li data-hook={VIEWER_HOOKS.BENEFIT} key={i}>
          <Benefit highlighted={highlighted}>{benefit}</Benefit>
        </li>
      ))}
    </ul>
  );
});

const flex1 = { flex: 1 };

const MobileBenefits: React.FC<Omit<BenefitsProps, 'mobile'>> = React.memo(({ benefits, highlighted }) => {
  const [expanded, setExpanded] = useState<boolean>(highlighted);
  const toggleExpanded = useCallback(() => setExpanded(!expanded), [setExpanded, expanded]);

  return (
    <div onClick={toggleExpanded} style={flex1}>
      <Collapsible trigger="" open={expanded} easing="ease-out">
        <Benefits mobile benefits={benefits} highlighted={highlighted} />
      </Collapsible>
      <ArrowDown dataHook={VIEWER_HOOKS.EXPAND_BENEFITS} className={st(classes.arrow, { expanded, highlighted })} />
    </div>
  );
});

const Benefit: React.FC<Pick<BenefitsProps, 'highlighted'>> = ({ children, highlighted }) => {
  const settings = useSettings();
  const styles = useStyles();
  const settingsParams = useSettingsParams();
  const stylesParams = useStylesParams();
  const { isRTL } = useEnvironment();

  const iconProps = () => {
    const lineHeight = 1.5;
    const defaultBenfitFontSize = 14;
    const fontSize = styles.get(stylesParams.planBenefitsFont).size ?? defaultBenfitFontSize;
    return { size: fontSize * lineHeight, flipped: isRTL, className: st(classes.bulletIcon, { highlighted }) };
  };

  const icon = () => {
    const bulletType = settings.get(settingsParams.benefitsBullet);
    if (bulletType === BenefitBulletType.NONE) {
      return null;
    } else {
      const IconComponent = iconsMap[bulletType];
      return <IconComponent {...iconProps()} />;
    }
  };
  return (
    <>
      {icon()}
      {children}
    </>
  );
};
