import React from 'react';
import { Button } from 'wix-ui-tpa/Button';
import { PublicPlan } from '@wix/ambassador-pricing-plans-read-api/types';
import { useTranslation, useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { useSettings, useStyles } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { VIEWER_HOOKS, EXPERIMENTS } from '../../../../../constants';
import { useSettingsParams } from '../../../../../hooks';
import { ButtonStyle } from '../../../settingsParams';
import stylesParams from '../../../stylesParams';
import { st, classes } from './Cta.st.css';

interface Props {
  plan: PublicPlan;
  highlighted: boolean;
  onClick: () => void;
}

enum ButtonStyleStylableFlag {
  emptyCornered = 'emptyCornered',
  emptyRounded = 'emptyRounded',
  fullCornered = 'fullCornered',
  fullRounded = 'fullRounded',
}

export const Cta: React.FC<Props> = ({ highlighted, plan, onClick }) => {
  const settingsParams = useSettingsParams();
  const settings = useSettings();
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const styles = useStyles();
  const { experiments } = useExperiments();
  const areAdditionalButtonSettingsEnabled = experiments.enabled(EXPERIMENTS.ADDITIONAL_BUTTON_SETTINGS_VIEWER);
  const hasFreeTrial = !!plan.paymentOptions?.freeTrialDays;

  const renderText = () => {
    if (hasFreeTrial) {
      return settings.get(settingsParams.freeTrialButtonText) || t('pp.defaults.freeTrialButton');
    }

    return settings.get(settingsParams.buttonText) || t('pp.defaults.button');
  };

  return (
    <Button
      data-hook={VIEWER_HOOKS.PLAN_CTA}
      onClick={onClick}
      className={st(classes.root, {
        highlighted,
        mobile: isMobile,
        style: buttonStyleToStylableFlag(styles.get(stylesParams.buttonStyle)),
        buttonWidthSettingEnabled: areAdditionalButtonSettingsEnabled,
      })}
    >
      {renderText()}
    </Button>
  );
};

function buttonStyleToStylableFlag(buttonStyle: ButtonStyle): ButtonStyleStylableFlag {
  switch (buttonStyle) {
    case ButtonStyle.EMPTY_CORNERED:
      return ButtonStyleStylableFlag.emptyCornered;
    case ButtonStyle.EMPTY_ROUNDED:
      return ButtonStyleStylableFlag.emptyRounded;
    case ButtonStyle.FULL_ROUNDED:
      return ButtonStyleStylableFlag.fullRounded;
    default:
    case ButtonStyle.FULL_CORNERED:
      return ButtonStyleStylableFlag.fullCornered;
  }
}
